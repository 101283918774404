import React from "react";
import Navbar from "../components/nav/Nav";
import Footer from "../components/nav/Footer";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Link from "../components/Link";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles({
  root: {
    marginTop: "6rem",
    marginBottom: "2rem",
  },
  marginBottom: {
    marginBottom: "2rem",
  },
  marginBottomBold: {
    marginBottom: "2rem",
    fontWeight: "bold",
  },
  bold: {
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  link: {
    cursor: "pointer",
  },
  gridTypo: {
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
});

export default function Datenschutz() {
  const classes = useStyles();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  return (
    <div className={classes.mainRoot}>
      <Navbar />
      <Grid container className={classes.root}>
        <Grid item xs={1} sm={2} md={2} />
        <Grid item xs={12} md={8} className={classes.gridTypo}>
          <Typography variant="h2" className={classes.marginBottomBold}>
            Datenschutzerklärung
          </Typography>
          <Typography variant="h3" className={classes.marginBottomBold}>
            1. Datenschutz auf einen Blick
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Allgemeine Hinweise
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Datenerfassung auf dieser Website
          </Typography>
          <Typography variant="h5" className={classes.bold}>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
            „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
            entnehmen.
          </Typography>
          <Typography variant="h5" className={classes.bold}>
            Wie erfassen wir Ihre Daten?
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
            Besuch der Website durch unsere ITSysteme erfasst. Das sind vor
            allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
            Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie diese Website betreten.
          </Typography>
          <Typography variant="h5" className={classes.bold}>
            Wofür nutzen wir Ihre Daten?
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </Typography>
          <Typography variant="h5" className={classes.bold}>
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
            Datenverarbeitung erteilt haben, können Sie diese Einwilligung
            jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
            unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
            sich jederzeit an uns wenden.
          </Typography>
          <Typography variant="h3" className={classes.marginBottomBold}>
            2. Hosting
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Externes Hosting
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Diese Website wird bei einem externen Dienstleister gehostet
            (Hoster). Die personenbezogenen Daten, die auf dieser Website
            erfasst werden, werden auf den Servern des Hosters gespeichert.
            Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta-
            und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
            Websitezugriffe und sonstige Daten, die über eine Website generiert
            werden, handeln.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
            gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
            lit. b DSGVO) und im Interesse einer sicheren, schnellen und
            effizienten Bereitstellung unseres Online-Angebots durch einen
            professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
            Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
            oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
            Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
            ist jederzeit widerrufbar.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
            Erfüllung seiner Leistungspflichten erforderlich ist und unsere
            Weisungen in Bezug auf diese Daten befolgen.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Wir setzen folgenden Hoster ein:
          </Typography>
          <div>
            <p>Vercel Inc. </p>
            <p>340 S Lemon Ave #4133 </p>
            <p>Walnut, California 91789 </p>
            <p>United States </p>
            <p>ar@vercel.com </p>
            <br />
            <br />
          </div>
          <Typography variant="h3" className={classes.marginBottomBold}>
            3. Allgemeine Hinweise und Pflichtinformationen
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Datenschutz
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend den gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
            mit denen Sie persönlich identifiziert werden können. Die
            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
            und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
            Zweck das geschieht.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Hinweis zur verantwortlichen Stelle
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
          </Typography>
          <div>
            <p>
              M3E GmbH - Fullserviceagentur und Beratung für Elektromobilität
            </p>
            <p>Torstraße 23</p>
            <p>10119 Berlin</p>
          </div>
          <div>
            <p>Telefon: 030/403672121</p>
            <p>E-Mail: info[@]m3e-gmbh.com</p>
          </div>
          <Typography variant="body1" className={classes.marginBottom}>
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten Dr. Christian Milan
            entscheidet.
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Speicherdauer
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere
            Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
            bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
            ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
            zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
            wir keine anderen rechtlich zulässigen Gründe für die Speicherung
            Ihrer personenbezogenen Daten haben (z. B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
            erfolgt die Löschung nach Fortfall dieser Gründe.
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung
            auf dieser Website
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
            wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1
            lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
            Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Sofern
            Sie in die Speicherung von Cookies oder in den Zugriff auf
            Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting)
            eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf
            Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit
            widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur
            Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten
            wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
            Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung
            einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von
            Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf
            Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
            DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
            Rechtsgrundlagen wird in den folgenden Absätzen dieser
            Datenschutzerklärung informiert.
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den
            USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten.
            Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in
            diese Drittstaaten übertragen und dort verarbeitet werden. Wir
            weisen darauf hin, dass in diesen Ländern kein mit der EU
            vergleichbares Datenschutzniveau garantiert werden kann.
            Beispielsweise sind US-Unternehmen dazu verpflichtet,
            personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne
            dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es
            kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B.
            Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
            Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern.
            Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine 5 / 10 bereits erteilte
            Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum
            Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
            ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
            DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
            VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
            DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING.
            DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
            ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
            EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
            MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE
            GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE
            UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
            GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
            (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
            BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
            VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
            DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
            SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
            WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
            NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH
            ART. 21 ABS. 2 DSGVO).
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Recht auf Datenübertragbarkeit
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            SSL- bzw. TLS-Verschlüsselung
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
            bzw. TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
            daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Auskunft, Löschung und Berichtigung
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den 6 / 10 Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
            uns wenden.
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Recht auf Einschränkung der Verarbeitung
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit an uns wenden. Das Recht auf Einschränkung der
            Verarbeitung besteht in folgenden Fällen:
          </Typography>
          <div>
            <ul>
              <li>
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                personenbezogenen Daten bestreiten, benötigen wir in der Regel
                Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
                das Recht, die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </li>
              <li>
                Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                geschah/geschieht, können Sie statt der Löschung die
                Einschränkung der Datenverarbeitung verlangen.
              </li>
              <li>
                Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
                sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
                Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                Löschung die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </li>
              <li>
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                haben, muss eine Abwägung zwischen Ihren und unseren Interessen
                vorgenommen werden. Solange noch nicht feststeht, wessen
                Interessen überwiegen, haben Sie das Recht, die Einschränkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>
            </ul>
          </div>
          <Typography variant="body1" className={classes.marginBottom}>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </Typography>
          <Typography variant="h3" className={classes.marginBottomBold}>
            4. Datenerfassung auf dieser Website
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Server-Log-Dateien
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Der Provider der Seiten erhebt und speichert automatisch
            Informationen in so genannten Server-LogDateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind:
          </Typography>
          <div>
            <ul>
              <li>Browsertyp und Browserversion</li>
              <li>verwendetes Betriebssystem</li>
              <li>Referrer URL</li>
              <li>Hostname des zugreifenden Rechners</li>
              <li>Uhrzeit der Serveranfrage</li>
              <li>IP-Adresse</li>
            </ul>
          </div>
          <Typography variant="body1" className={classes.marginBottom}>
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
            nicht vorgenommen.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
            lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
            der technisch fehlerfreien Darstellung und der Optimierung seiner
            Website – hierzu müssen die Server-Log-Files erfasst werden.
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Kontaktformular
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
            uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck für die Datenspeicherung
            entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
            Zwingende gesetzliche Bestimmungen – insbesondere
            Aufbewahrungsfristen – bleiben unberührt.
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Anfrage per E-Mail, Telefon oder Telefax
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Die von Ihnen an uns per Kontaktanfragen übersandten Daten
            verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
            Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere
            gesetzliche Aufbewahrungsfristen – bleiben unberührt.
          </Typography>
          <Typography variant="h3" className={classes.marginBottomBold}>
            5. Plugins und Tools
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Google Web Fonts (lokales Hosting)
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
            genannte Web Fonts, die von Google bereitgestellt werden. Die Google
            Fonts sind lokal installiert. Eine Verbindung zu Servern von Google
            findet dabei nicht statt.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Weitere Informationen zu Google Web Fonts finden Sie unter
            <Link
              href="https://developers.google.com/fonts/faq"
              external
              className={classes.link}
            >
              https://developers.google.com/fonts/faq
            </Link>
            und in der Datenschutzerklärung von Google:
            <Link
              href="https://policies.google.com/privacy?hl=de"
              external
              className={classes.link}
            >
              https://policies.google.com/privacy?hl=de
            </Link>
          </Typography>
          <Typography variant="h4" className={classes.marginBottomBold}>
            Google reCAPTCHA
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf dieser
            Website. Anbieter ist die Google Ireland Limited („Google“), Gordon
            House, Barrow Street, Dublin 4, Irland.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf dieser
            Website (z. B. in einem Kontaktformular) durch einen Menschen oder
            durch ein automatisiertes Programm erfolgt. Hierzu analysiert
            reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener
            Merkmale. Diese Analyse beginnt automatisch, sobald der
            Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA
            verschiedene Informationen aus (z. B. IP-Adresse, Verweildauer des
            Websitebesuchers auf der Website oder vom Nutzer getätigte
            Mausbewegungen). Die bei der Analyse erfassten Daten werden an
            Google weitergeleitet.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Die reCAPTCHA-Analysen laufen vollständig im Hintergrund.
            Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse
            stattfindet.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art.
            6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
            Interesse daran, seine Webangebote vor missbräuchlicher
            automatisierter Ausspähung und vor SPAM zu schützen. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
            Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
            oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
            Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
            ist jederzeit widerrufbar.
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Weitere Informationen zu Google reCAPTCHA entnehmen Sie den
            Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen
            unter folgenden Links:
            <Link
              href="https://policies.google.com/privacy?hl=de"
              external
              className={classes.link}
            >
              https://policies.google.com/privacy?hl=de
            </Link>
            und
            <Link
              href="https://policies.google.com/terms?hl=de"
              external
              className={classes.link}
            >
              https://policies.google.com/terms?hl=de
            </Link>
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Quelle:
            <Link
              href="https://www.e-recht24.de/"
              external
              className={classes.link}
            >
              https://www.e-recht24.de/
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <Footer />
    </div>
  );
}
